const isNumericInput = (event) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

const isAlphaInput = (event) => {
  const key = event.keyCode;
  return (
    key > 64 && key < 91 // letter keys
  );
};

const isSpecialCharacter = (event) => {
  const key = event.keyCode;
  return (
    (key > 96 && key < 123) ||
    (key >= 48 && key <= 57) ||
    (key >= 219 && key <= 221) ||
    (key >= 186 && key <= 192) ||
    key === 222 ||
    key === 189
  );
};

const isDeleteOrTabKey = (event) => {
  const key = event.keyCode;
  return key === 8 || key === 9 || key === 46;
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

const spaceBarKey = (event) => event.keyCode === 32;

const enforceNumberFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

const enforceNonSpecialCharacters = (event) => {
  if (isSpecialCharacter(event)) event.preventDefault();
};

const enforceKeyFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isAlphaInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

const enforceCityFormat = (event) => {
  if (!isAlphaInput(event) && !isModifierKey(event) && !spaceBarKey(event)) {
    event.preventDefault();
  }
};

const enforceFullName = (event) => {
  if (isNumericInput(event) || !isDeleteOrTabKey) {
    event.preventDefault();
  }
};

const formatToPhone = (event) => {
  if (isModifierKey(event)) {
    return;
  }

  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  let areaCode = input.substring(0, 3);
  if (areaCode[0] === "0" || areaCode[0] === "1") {
    areaCode = areaCode.substring(1);
  }
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `${areaCode}-${middle}-${last}`;
  } else if (input.length > 3) {
    target.value = `${areaCode}-${middle}`;
  } else if (input.length > 0) {
    target.value = `${areaCode}`;
  }
};

function formattedDOB(year, month, day) {
  let fullMonth, fullDay;
  month < 10 && month.length === 1
    ? (fullMonth = `0${month}`)
    : (fullMonth = month);
  day < 10 && day.length === 1 ? (fullDay = `0${day}`) : (fullDay = day);

  return `${year}-${fullMonth}-${fullDay}T00:00:00`;
}

export {
  formatToPhone,
  enforceCityFormat,
  enforceNumberFormat,
  enforceKeyFormat,
  enforceFullName,
  isNumericInput,
  enforceNonSpecialCharacters,
  isAlphaInput,
  isModifierKey,
  formattedDOB,
};

// TODO: This file needs a TEST!!!!
