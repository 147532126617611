// only edit if you have sign off -
// these colors have been agreed upon by stakeholders and we just want to make sure
// no one makes any ad hoc changes here without going thru the proper channels

module.exports = {
	white: `#F0F0F0`,
	whitePure: `#FFFFFF`,
	whiteAlt: `#F9F2EA`,
	peach: `#F69A6C`,
	yellow: `#FFC93F`,
	grey: `#CCDDEE`,
	blue: `#022859`,
	blueLight: `#0057A8`,
	black: `#010A16`,
	blackPure: `#000000`,
	blueAlt: `#143F69`,
	bluePale: `#CCDDEE`,
	blueExtraPale: `#E9F4F5`,
	bluePaleAlt: `#EBF1F8`,
	blueDeepSky: `#3BB9FF`,
	customGray: "#333C4E",
	bpLightPink: "#f5f0f0",
	bpMedPink: "#d3aeb2",
	bpBrightPink: "#f1837f",
	gray1: `#C2C2C2`,
	gray2: `#CCCCCC`,
	gray3: `#707070`,
	gray4: `#333333`,
	gray5: `#515151`,
	grayPale: `#F3F5F7`,
	red: `#B30000`,
	redAlt: `#B00020`,
	purple: `#800080`,
};
