import React, { useEffect, useState } from "react";
import Header from "components/Header";
import "./App.css";
import Form from "./form";
import {
	submitPatientLookup,
	currentQueryParams,
	submitUserform,
} from "./utils/axiosUtils.js";
import LoadingSpinner from "./components/LoadingSpinner";

function App() {
	const [formFields, setformFields] = useState(null);
	const [showThankYou, setShowThankYou] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
	const [hash, setHash] = useState(null);

	const checkForMissingValues = (patientData) => {
		const {
			address: { city, street, zip },
			dob,
			due_date,
			email_address,
			first_name,
			last_name,
			phone,
			payers: { payer1_hic, payer1_name, payer1_id },
		} = patientData;

		const fields = [
			city,
			street,
			zip,
			dob,
			due_date,
			email_address,
			first_name,
			last_name,
			phone,
			payer1_hic,
			payer1_name,
			payer1_id,
		];

		const needData = fields.filter((field) => field === "" || field === null);
 
		if (needData.length) {
			setformFields(patientData);
			setShowLoader(false);
		} else {
			setShowLoader(false);
			setShowThankYou(true);
		}
	};

	useEffect(() => {
		const hash = currentQueryParams.get("sgh");
		setHash(hash);

		submitPatientLookup(hash)
			.then((data) => {
				checkForMissingValues(data.data.patient);
			})
			.catch((err) => {
				window.location.replace("https://aeroflowbreastpumps.com/");
			});
	}, []);

	const submitForm = (data) => {
		setSubmitButtonDisabled(true);
		submitUserform(data)
			.then((res) => {
				if (res.data) {
					setShowThankYou(true);
				}
			})
			.catch((err) => {
				setSubmitButtonDisabled(false);
			});
	};

	if (showLoader)
		return (
			<div className="mt-10">
				<LoadingSpinner height="7rem" width="7rem" rings={6} />
			</div>
		);

	return (
		<div className="bg-white h-screen sans">
			<Header />
			<div className="p-7 flex flex-col justify-center align-middle text-gray-600 text-center">
				{showThankYou ? (
					<div className="text-xl">
						<p>{`Thank you for updating your insurance information! `}</p>
						<p>
							{`We will be in touch as soon as your insurance-covered breast pump options are available.`}
						</p>
					</div>
				) : (
					<>
						<h4 className="text-3xl mb-4 font-serif">
							Update Your Insurance Information
						</h4>
						<p className="w-full sm:w-1/2 m-auto mb-4">{`Don't miss out on using your insurance benefit towards a new breast pump!`}</p>
						<p className="w-full sm:w-1/2 m-auto mb-4">
							{`Please update your insurance information below to verify coverage for your pump. If you have any questions, please contact us at 844-867-9890.`}
						</p>

						<div className="flex justify-center align-middle">
							<div className="p-6 w-full sm:w-2/3 lg:w-1/2 bg-bpLightPink text-gray-600">
								<Form
									formFields={formFields}
									handleSubmitForm={submitForm}
									patientHash={hash}
									submitButtonDisabled={submitButtonDisabled}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default App;
