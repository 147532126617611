import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "./components/Input";
import { getStatePayers } from "utils/axiosUtils";
import {
	otherMedicaidPlusMedicare,
	selectDefault,
} from "utils/otherPayers";
import { enforceNumberFormat, formattedDOB } from "utils/formatFields";

import states from "utils/states";

export default function Form({
	formFields,
	handleSubmitForm,
	patientHash,
	submitButtonDisabled,
}) {
	const [insuranceTypes, setInsuranceTypes] = useState([
		{ primary_payer_pk: "", friendly_name: "Select a State Above" },
	]);
	const [showStateSelect, setShowStateSelect] = useState(false);
	const [patientFields, setPatientFields] = useState([]);
	const [isInsuranceSelectDisabled, setInsuranceSelectDisabled] =
		useState(true);

	const [showOtherInsuranceCarrier, setShowOtherInsuranceCarrier] =
		useState(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		clearErrors,
	} = useForm();

	function handleStateSelect(state) {
		if (state !== null) {
			setValue("state", state);
			getStatePayers(state)
				.then((results) => {
					setInsuranceTypes([selectDefault, ...results.data]);
					setInsuranceSelectDisabled(false);
				})
				.catch(() => {
					setInsuranceTypes([selectDefault, ...otherMedicaidPlusMedicare]);
					setInsuranceSelectDisabled(false);
				});
		}
	}

	function handleInsuranceSelect(e) {
		const selectedCarrier = e.currentTarget.value;
		if (e.currentTarget.value === "other") {
			setShowOtherInsuranceCarrier(true);
		} else {
			setShowOtherInsuranceCarrier(false);
			setValue("insurance_primary_provider", selectedCarrier);
			clearErrors("insurance_primary_provider");
			setValue("insurance_primary_provider_other", null);
		}
	}

	const onSubmit = (data) => {
		const {
			payers: {
				payer1_id,
				payer1_hic,
				payer2_id,
				payer2_hic,
				payer2_group_number,
				payer3_id,
				payer3_hic,
				payer3_group_number,
			},
		} = formFields;

		const finalFormData = {
			patient_hash: patientHash,
			first_name: formFields.first_name || data.firstName,
			last_name: formFields.last_name || data.lastName,
			payer1_group_number: "",
			email_address: data.email_address || formFields.email_address,
			dr_npi: formFields.doctor.npi,
			dob:
				data.year !== undefined
					? formattedDOB(data.year, data.month, data.day)
					: formFields.dob,
			due_date:
				data.dueYear !== undefined
					? formattedDOB(data.dueYear, data.dueMonth, data.dueDay)
					: formFields.due_date,
			phone: data.phone || formFields.phone,
			street: data.street || formFields.street,
			city: data.city || formFields.address.city,
			state: data.state || formFields.state,
			zip: data.zip || formFields.address.zip,
			payer1_parent_payer_pk:
				data.insurance_primary_provider || payer1_id,
			payer1_member_id: data.insurance_primary_policy_number || payer1_hic,
			payer2_parent_payer_pk: payer2_id || 0,
			payer2_member_id: payer2_hic,
			payer2_group_number,
			payer3_parent_payer_pk: payer3_id || 0,
			payer3_member_id: payer3_hic,
			payer3_group_number,
		};
		handleSubmitForm(finalFormData);
	};

	useEffect(() => {
		if (formFields) {
			const {
				address: { state },
			} = formFields;

			if (state !== "") handleStateSelect(state);

			for (const [key, value] of Object.entries(formFields.address)) {
				if (value === "" || value === null) {
					formFields.address.city = "";
					formFields.address.street = "";
					formFields.address.zip = "";
					setShowStateSelect(true);
				}
			}

			const {
				address: { street, city, zip },
			} = formFields;

			// Pull out nested address fields and remove unneeded
			const vals = { ...formFields, street, city, zip };
			
			const { deductible, coinsurance, oop_remaining, dob, ...flattenedVals } =
				vals;
 
			for (const [key, value] of Object.entries(flattenedVals)) {
				const fieldNames = {
					name: key,
					label: key,
					dbName: key,
					required: true,
				};

				if ((value === "" || value === null) && key !== 'due_date') {
					setPatientFields((patientFields) => [...patientFields, fieldNames]);
				}
			}
		}

		if (
			(formFields && formFields.payers.payer1_id === 0) ||
			(formFields && formFields.payers.payer1_hic === null) ||
			(formFields && formFields.payers.payer1_hic === "") ||
			(formFields && formFields.payers.payer1_hic === null) ||
			(formFields && formFields.payers.payer1_name === "")
		) {
			setShowStateSelect(true);
		}
	}, [formFields, setValue]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
				<div className="flex flex-wrap justify-between">
				{patientFields.map((field, key) => (
					<Input
						key={field.name}
						tempId={key}
						label={field.label}
						registration={field.name}
						register={register}
						error={errors[field.name]}
						required={field.required}
						type={field.type}
						dynamicClasses={`w-full mt-2 ${
							key % 2 === 0 ? `md:pr-1` : `md:pl-1`
						}`}
					/>
				))}
			</div>

			{formFields && showStateSelect && (
				<>
					<div className="field">
						<label htmlFor="state" className="required pl-2 block text-left">
							State <span className="asterisk">&#42;</span>
						</label>
						<div className="input-box mt-2">
							<select
								{...register("state", { required: true })}
								className="mb-4 text-black p-2 rounded-lg w-full"
								onChange={(e) => handleStateSelect(e.currentTarget.value)}
							>
								{states.map((state) => (
									<option
										value={state.value !== null ? state.value : state.name}
										key={state.value}
									>
										{state.name}
									</option>
								))}
							</select>
							{errors.state && (
								<div className="w-full text-left pl-2 text-red-600 mb-4">{`State is required`}</div>
							)}
						</div>
					</div>
					<div className="field">
						<label
							htmlFor="insurance carrier"
							className="required pl-2 block text-left"
						>
							Insurance Carrier <span className="asterisk">&#42;</span>
						</label>
						<div className="input-box mt-2">
							<select
								{...register("insurance_primary_provider", { required: true })}
								className="mb-4 text-black p-2 rounded-lg w-full"
								disabled={isInsuranceSelectDisabled}
								onChange={(e) => handleInsuranceSelect(e)}
							>
								{insuranceTypes.map((state) => 
								(
									 <option
									 value={state.parent_payer_pk !== null ? state.parent_payer_pk : state.friendly_name}
									 key={state.friendly_name}
								 >
									 {state.friendly_name}
								 </option>
								))}
							</select>
						</div>
					</div>
					{errors.insurance_primary_provider && (
						<div className="w-full text-left pl-2 text-red-600 mb-4">{`Insurance Carrier is required`}</div>
					)}
					{formFields && showOtherInsuranceCarrier && (
						<Input
							label="Other insurance Carrier"
							registration="insurance_primary_provider_other"
							register={register}
							error={errors.insurance_primary_provider_other}
							required
							dynamicClasses={`w-full`}
						/>
					)}
					<div className="field">
						<Input
							label="Policy Number"
							placeholder="Member ID"
							registration="insurance_primary_policy_number"
							register={register}
							error={errors.insurance_primary_policy_number}
							type="text"
							required
							dynamicClasses={`mb-4 text-black rounded-lg w-full`}
						/>
					</div>
				</>
			)}

			{formFields && formFields.dob === null && (
				<>
					<label
						className="w-full pl-2 block text-left"
						htmlFor="date of birth"
					>{`Date of Birth`}</label>

					<div className="flex justify-between flex-wrap">
						<Input
							label="Day"
							placeholder="Day"
							registration="day"
							register={register}
							handleOnKeyDown={(e) => enforceNumberFormat(e)}
							error={errors.day}
							maxLength={2}
							type="tel"
							hideLabel
							required
							dynamicClasses={`w-full sm:w-1/3 sm:pr-2`}
						/>

						<Input
							label="Month"
							placeholder="Month"
							registration="month"
							register={register}
							error={errors.month}
							type="tel"
							maxLength={2}
							hideLabel
							required
							dynamicClasses={`w-full sm:w-1/3`}
						/>

						<Input
							label="Year"
							placeholder="Year"
							registration="year"
							register={register}
							error={errors.year}
							maxLength={4}
							type="tel"
							hideLabel
							required
							dynamicClasses={`w-full md:w-1/3 sm:pl-2`}
						/>
					</div>
				</>
			)}

			{formFields && formFields.due_date === null && (
				<>
					<label
						className="w-full pl-2 block text-left"
						htmlFor="Due Date"
					>{`Due Date`}</label>
					<div className="flex justify-between flex-wrap">
						<Input
							placeholder="Day"
							label="Day"
							registration="dueDay"
							register={register}
							error={errors.dueDay}
							type="tel"
							required
							maxLength={2}
							dynamicClasses={`sm:w-full md:w-1/3 md:pr-2`}
						/>

						<Input
							placeholder="Month"
							label="Month"
							registration="dueMonth"
							register={register}
							error={errors.dueMonth}
							type="tel"
							required
							maxLength={2}
							dynamicClasses={`w-full sm:w-1/3`}
						/>

						<Input
							placeholder="Year"
							label="Year"
							registration="dueYear"
							register={register}
							error={errors.dueYear}
							type="tel"
							required
							maxLength={4}
							dynamicClasses={`w-full md:w-1/3 sm:pl-2`}
						/>
					</div>
				</>
			)}
			<div className="flex justify-center mt-3">
				<button
					className="mb-4 bg-red w-28 p-2 bg-bpMedPink text-white rounded-lg"
					disabled={submitButtonDisabled}
					type="submit"
				>
					Submit
				</button>
			</div>
		</form>
	);
}
