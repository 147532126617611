import axios from "axios";
const currentQueryParams = new URLSearchParams(window.location.search);

const getStatePayers = async (state) => {
  const url = `${process.env.REACT_APP_GET_STATE_PAYERS_URL}`
 
  const response = await fetch(`${url}/?state=${state}&url=https://aeroflowbreastpumps.com/qualify-through-insurance`)

  return await response.json()
}

// POST
const submitPatientLookup = async (hash) => {
  
  const response = await fetch(`${process.env.REACT_APP_GET_PATIENTS_URL}/product-pricing`, {
    headers: {Authorization: `PatientHash ${hash}`}
  })

  return await response.json()
}

const submitUserform = async (data) =>
  await axios.post(
    `https://dynamic-form-collection.aeroflowapi.io/dynamic-form-collection`,
    data
  );

export {
  submitPatientLookup,
  currentQueryParams,
  getStatePayers,
  submitUserform,
};
