import React from "react";
import logo from "../img/bp-logo.svg";
function Header() {
	return (
		<div className="h-24 bg-white flex items-center justify-center p-5">
			<img className="w-60" src={logo} alt="Aeroflow Logo" />
		</div>
	);
}

export default Header;
