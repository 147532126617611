const otherDefault = { name: "Other", value: "other" };

const selectDefault = {
  friendly_name: "Select Insurance Carrier",
  parent_payer_pk: "",
};

const medicaid = { name: "Medicaid", value: "MEDICAID" };

const medicare = { name: "Medicare", value: "MEDICARE" };

const otherMedicaid = [medicaid, otherDefault];

const otherMedicaidPlusMedicare = [medicare, ...otherMedicaid];

export {
  otherMedicaid,
  otherMedicaidPlusMedicare,
  otherDefault,
  selectDefault,
  medicaid,
  medicare,
};
