import React from "react";
import {
  enforceNumberFormat,
  enforceKeyFormat,
  enforceCityFormat,
} from "utils/formatFields";

const Input = ({
  label,
  placeholder,
  registration,
  register,
  required,
  type = "text",
  error,
  handleBlur,
  dynamicClasses,
  hideLabel,
  maxLength,
}) => {
  const handleOnKeydown = (e) => {
    switch (label) {
      case "city":
        enforceCityFormat(e);
        break;
      case "zip":
        enforceNumberFormat(e);
        break;
      case "first_name":
        enforceKeyFormat(e);
        break;
      case "last_name":
        enforceKeyFormat(e);
        break;

      default:
        break;
    }
  };

  return (
    <div className={dynamicClasses}>
      {label && !hideLabel && (
        <label
          className="text-left block font-medium w-full pl-2 capitalize"
          htmlFor={label}
        >
          {`${label} `} {required && <>*</>}
        </label>
      )}
      <input
        type={type}
        onBlur={handleBlur}
        maxLength={label === "zip" ? 5 : maxLength}
        onKeyDown={(e) => handleOnKeydown(e)}
        placeholder={placeholder}
        className="mb-2 mt-2 text-black p-2 rounded-lg w-full"
        {...register(registration, { required })}
      />
      {required && error && (
        <div className="w-full text-left pl-2 text-red-600 mb-4">{`${label} is required`}</div>
      )}
    </div>
  );
};

export default Input;
